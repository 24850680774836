import classNames from 'classnames'

export default function BackgroundMapper(backgroundColor: string) {
  const backgroundColorClass = classNames({
    'bg-dats-s10': backgroundColor === 'light grey',
    'bg-dats-s4': backgroundColor === 'light blue',
    'bg-dats-border-s2': backgroundColor === 'light green',
    'bg-dats-s2-light': backgroundColor === 'light limon green',
    'bg-white': backgroundColor === 'white',
  })
  return backgroundColorClass
}
